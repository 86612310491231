import var2js from "./var2js";

function vm2js(vm) {
  if (vm === undefined || vm === null || vm === "") return null;
  if (typeof vm === "object") {
    let js = Array.isArray(vm) ? [] : {};
    for (let key in vm) {
      if (key[0] === "_" || key[0] === "$" || key.includes("4List")) continue;
      if (typeof vm[key] === "function") continue;
      if (vm[key] === null) js[key] = null;
      else js[key] = vm2js(vm[key]);
    }
    return js;
  } else {
    return var2js(vm);
  }
}

export default vm => {
  return vm2js(vm);
};
